<template>
  <div class="index_page">
    <div class="content_container">
      <routeHeader selectedKey="order" />
      <div class="content">
        <div class="table">
          <div class="table_header">
            <span class="info-item">时长</span>
            <span class="info-item">授权账号</span>
            <span class="info-item">支付方式</span>
            <span class="info-item">支付金额</span>
            <span class="info-item">交易状态</span>
          </div>
          <div class="table_item" v-for="item in orderList" :key="item.id">
            <div class="table_item_header">
              {{ item.orderTimeStr }} | 订单号:{{ item.id }}
            </div>
            <div class="table_item_content">
              <span class="info-item">{{ item.showTime || '-' }}</span>
              <div
                class="info-item flex-col"
                v-if="item.tiktokCompanies && item.tiktokCompanies.length > 0"
              >
                <span
                  v-for="(sonItem, index) in item.tiktokCompanies"
                  :key="index"
                >
                  {{ sonItem.tiktokCompanyName || '-' }}</span
                >
              </div>
              <span class="info-item" v-else>-</span>
              <span class="info-item">{{ payTypeStr[item.payType] }}</span>
              <span class="info-item">{{ item.payAmount }}</span>
              <span class="info-item">{{ item.orderStatusStr }}</span>
            </div>
          </div>
          <div class="pagination-container">
            <a-pagination
              :show-total="total => `共 ${total} 条订单`"
              class="mt20"
              show-size-changer
              :default-current="params.pageSize"
              :total="count"
              @change="onChangePage"
              @showSizeChange="onShowSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      payTypeStr: {
        0: '系统赠送',
        1: '微信支付',
        2: '支付宝支付',
        3: '系统支付'
      },
      orderList: [],
      count: 0,
      params: {
        pageSize: 10,
        PageNumber: 1
      },
      isRechargeShow: false,
      advertiserCompanyId: ''
    }
  },
  mounted () {
    this.orderPage()
  },
  methods: {
    onChangePage (current, pageSize) {
      console.log(current, pageSize)
      this.params.pageSize = pageSize
      this.params.PageNumber = current
      this.orderPage()
    },
    onShowSizeChange (current, pageSize) {
      console.log(current, pageSize)
      this.params.pageSize = pageSize
      this.params.PageNumber = 1
      this.orderPage()
    },
    orderPage () {
      this.$api.order.orderPage(this.params).then(res => {
        console.log(res)
        this.count = res.count
        let orderList = res.items
        const orderStatusTree = {
          1: '未支付',
          2: '支付中',
          3: '支付成功',
          4: '支付失败',
          5: '订单过期'
        }
        orderList = orderList.map(item => {
          item.orderTimeStr = this.$moment(item.orderTime).format(
            'YYYY年MM月DD日 HH:mm'
          )
          item.orderStatusStr = orderStatusTree[item.orderStatus]
          return item
        })
        this.orderList = orderList
      })
    }
  }
}
</script>

<style lang="less" scoped>
.index_page {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f3f3f3;
  width: 100%;
  padding: 24px;
  height: 100%;
  .content_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .content {
    flex: 1;
    display: flex;
    background: #fff;
    width: 100%;
    overflow: auto;
    padding: 24px;
    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      .table_header {
        background: #fafafa;
        border: 1px solid #eeeeee;
        color: #282828;
        font-size: 13px;
        padding: 0px 22px;
        height: 44px;
        display: flex;
        align-items: center;
      }
      .table_item {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        .table_item_header {
          border: 1px solid #eeeeee;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #555555;
          background: #f3f3f3;
          padding: 0px 22px;
          height: 44px;
        }
        .table_item_content {
          border: 1px solid #eeeeee;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: #555;
          padding: 15px 20px;
        }
      }
      .info-item {
        display: flex;
        flex: 1;
      }
    }
  }
}
.pagination-container {
  display: flex;
  flex-direction: row-reverse;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
</style>
